<template>
  <div :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" class="mb-3 card rounded-t">
    <div>
      <v-tabs v-model="tab" class="rounded-t">
        <v-tab key="baseData">
          {{ $t('customers.lang_kundenprofil') }}
        </v-tab>

        <v-tab key="finance" v-if="this.$store.getters['permissions/checkPermission']('customerFinance')">{{ $t('generic.lang_finance') }}</v-tab>

        <v-tabs-items touchless v-model="tab">
          <v-tab-item key="baseData">
            <v-container fluid>
              <v-layout wrap>
                <v-flex md5 xs12>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="company"
                                :label="$t('generic.lang_company')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <!-- customer department -->
                  <v-select dense :items="departments"
                            :label="$t('customers.lang_customerDepartments')"
                            item-text="name"
                            item-value="id"
                            outlined
                            v-model="department"
                            :loading="departments_loader"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon>arrow_drop_down</v-icon>
                    </template>


                    <template v-slot:append>
                      <v-menu
                          :close-on-content-click="false"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          :nudge-width="200"
                          id="menu_create"
                          max-width="304"
                          offset-y
                          transition="slide-x-transition"
                          v-model="create_department_menu"
                          z-index="10"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn @click="showDepartmentMenu" color="primary"
                                 fab
                                 id="icon-wiregroup-add" small
                                 v-on="on">
                            <v-icon>add</v-icon>
                          </v-btn>
                        </template>
                        <v-card outlined>
                          <v-row align="center"
                                 class="dropdown-menu-header"
                                 justify="center">
                            <div class="dropdown-menu-header-inner bg-primary"
                            >
                              <div class="menu-header-image dd-header-bg-1"></div>
                              <div class="menu-header-content text-center pa-3 ma-0"
                              >
                                <div class="text-center mx-3"><h5
                                    class="menu-header-title ">
                                  {{ $t('customers.lang_customerDepartmentsAdd') }}</h5>
                                  <span class="mx-auto text-center mx-3">
                                             {{ $t('customers.lang_addDepartmentText') }}
                                            </span>
                                </div>
                              </div>
                            </div>
                          </v-row>
                          <div class="scroll-area-xl pa-2">
                            <v-form class="ma-0 pa-0"
                                    lazy-validation
                                    onsubmit="return false"
                                    ref="departmentForm">

                              <v-row justify-md="center">
                                <v-col cols="12">
                                  <v-text-field v-model="new_department.departmentName"
                                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                :label="$t('customers.lang_departmentName')"
                                                autocomplete="off"
                                                :rules="[v=>!!v && v.length>0 ||$t('generic.lang_requiredField')]"
                                                outlined
                                                required
                                                @focus="showTouchKeyboard"
                                  ></v-text-field>
                                </v-col>

                              </v-row>

                              <v-divider class="pa-0 ma-0"/>
                              <div class="text-center">
                                <button @click="create_department_menu = false"
                                        class="btn btn-danger ma-2">
                                  {{ $t('generic.lang_cancel') }}
                                </button>

                                <v-btn color="primary"
                                       :loading="loadingCreateDepartment"
                                       @click="createDepartment"
                                       :disabled="new_department.name===''||new_department.name===null">
                                  {{ $t('generic.lang_create') }}
                                </v-btn>
                              </div>
                            </v-form>
                          </div>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-select>

                  <v-select outlined
                            :items="salutations"
                            item-text="name"
                            item-value="id"
                            v-model="salutation"
                            :label="$t('customers.lang_cust_anrede')"
                  ></v-select>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="preName"
                                :label="$t('customers.lang_cust_prename')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="lastName"
                                :label="$t('customers.lang_cust_name')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="streetName"
                                :label="$t('generic.lang_street')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="streetNumber"
                                :label="$t('customers.lang_cust_streetNo')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="zip"
                                :label="$t('customers.lang_cust_zip')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="city"
                                :label="$t('settings.lang_city')"
                                autocomplete="off"
                  >
                  </v-text-field>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="country"
                                :label="$t('generic.lang_country')"
                                autocomplete="off"
                  >
                  </v-text-field>

                  <v-textarea outlined
                              name="termsOfPayment"
                              v-model="receiptNotes"
                              :label="$t('accounting.lang_InvoiceNotes')"
                              value=""
                  ></v-textarea>
                </v-flex>
                <v-flex md1 xs0>
                </v-flex>
                <v-flex md6 xs12>

                  <BirthdayPicker :label="$t('customers.lang_cust_birth')" v-model="birthday"/>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="email"
                                :label="$t('generic.lang_emailAdresse')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="website"
                                :label="$t('generic.lang_Website')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="tel_private"
                                :label="$t('customers.lang_cust_tel_priv')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="mobil_private"
                                :label="$t('customers.lang_cust_mobil_priv')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="tel_business"
                                :label="$t('customers.lang_cust_tel_business')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="mobil_business"
                                :label="$t('customers.lang_cust_mobil_business')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="fax"
                                :label="$t('generic.lang_fax')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>
                  <v-select outlined
                            :items="custGroups"
                            item-text="name"
                            item-value="id"
                            v-model="custGroupChooser"
                            :label="$t('customers.lang_custGroup')"
                  ></v-select>
                  <v-textarea outlined
                              name="termsOfPayment"
                              v-model="notes"
                              :label="$t('generic.lang_internalNotes')"
                              value=""
                  ></v-textarea>

                </v-flex>
              </v-layout>
              <v-row>
                <v-col cols="12">
                  <MapLocationPicker v-if="!true" v-model="position"/>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="this.$store.getters['permissions/checkPermission']('customerFinance')" key="finance">

            <v-container fluid>
              <v-layout wrap>
                <v-flex md5 xs12>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="vatID"
                                :label="$t('accounting.lang_VATID')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="debitorAccount"
                                :label="$t('accounting.lang_debitorKonto')"
                                autocomplete="off"
                  >
                  </v-text-field>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="iban"
                                label="IBAN"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="accountOwner"
                                :label="$t('settings.lang_accountOwner')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="mandatRef"
                                :label="$t('accounting.lang_MandateReference')"
                                autocomplete="off"
                  >
                  </v-text-field>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="paymentDueTerms"
                                :label="$t('accounting.lang_paymentTermsInDays')"
                                autocomplete="off"
                  >
                  </v-text-field>

                  <v-radio-group
                      v-model="payment_type"
                      column
                      :label="$t('customers.lang_preferedInvoicePayment')"
                      class="d-flex"
                      hide-details
                  >
                    <v-radio
                        :label="$t('accounting.lang_bankTransfer')"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        :label="$t('accounting.lang_directDebit')"
                        :value="2"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex md1 xs0>
                </v-flex>
                <v-flex md6 xs12>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="kreditorAcc"
                                :label="$t('accounting.lang_vendorAccount')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="bic"
                                label="BIC"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="bankName"
                                :label="$t('generic.lang_bankname')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>

                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="creditorID"
                                :label="$t('accounting.lang_CreditorID')"
                                autocomplete="off"
                                required
                  >
                  </v-text-field>
                  <v-textarea outlined
                              name="termsOfPayment"
                              v-model="termsOfPayment"
                              :label="$t('accounting.lang_termsOfPayment')"
                              value=""
                  ></v-textarea>

                  <v-checkbox :label="$t('customers.lang_enableCustomerForInvoices')" v-model="bill_allowed"/>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <v-container fluid>
        <v-layout>
          <v-flex class="text-right">
            <v-btn text color="error" @click="goBacktoCustomers">{{ $t('generic.lang_prev') }}</v-btn>
            <v-btn color="success" :disabled="preName.length < 1 || lastName.length < 1" @click="addData">{{
                $t('generic.lang_add')
              }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import BirthdayPicker from "../common/birthdaypicker";
import mixin from "../../mixins/KeyboardMixIns";
import MapLocationPicker from "@/components/common/MapLocationPicker";


export default {
  components: {
    MapLocationPicker,
    BirthdayPicker
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      new_department: {
        departmentName: ""
      },
      loadingCreateDepartment: false,
      create_department_menu: false,
      departments_loader: false,
      salutations: [],
      departments: [],
      custGroups: [],
      payment_type: 2,
      select: '',
      id: null,
      itemAdd: "",
      loading: false,
      tab: 0,
      company: "",
      salutation: "",
      department: "",
      preName: "",
      lastName: "",
      streetName: "",
      streetNumber: "",
      zip: "",
      city: "",
      country: "",
      receiptNotes: "",
      birthday: "",
      email: "",
      website: "",
      tel_private: "",
      mobil_private: "",
      tel_business: "",
      mobil_business: "",
      fax: "",
      custGroupChooser: "",
      notes: "",
      vatID: "",
      debitorAccount: "",
      iban: "",
      accountOwner: "",
      mandatRef: "",
      paymentDueTerms: "",
      kreditorAcc: "",
      bic: "",
      bankName: "",
      creditorID: "",
      termsOfPayment: "",
      bill_allowed: false,
      position: [],

    }
  },

  watch: {},

  methods: {
    loadDepartments() {
      this.departments_loader = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTDEPARTMENTS.GET_ALL).then((res) => {
        if (Array.isArray(res.data.results))
          this.departments = res.data.results.map((dp) => ({
            id: dp.id,
            name: dp.departmentName
          }))
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.departments_loader = false;
      })
    },
    createDepartment() {
      if (!this.$refs.departmentForm.validate()) return;

      this.loadingCreateDepartment = true;

      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTDEPARTMENTS.CREATE, this.new_department).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('customers.lang_departmentAdded'),
            color: "success"
          });
          this.$refs.departmentForm.reset();
          this.create_department_menu = false;
          this.loadDepartments();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadingCreateDepartment = false
      });
    },
    showDepartmentMenu() {
      this.create_department_menu = true;
    },
    goBacktoCustomers: function () {
      this.$router.push('/customer/customers');
    },
    addData: function () {
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.CREATE, {

        company: this.company,
        salutation: this.salutation,
        department: this.department,
        first_name: this.preName,
        last_name: this.lastName,
        street: this.streetName,
        street_no: this.streetNumber,
        zip: this.zip,
        city: this.city,
        country: this.country,
        receiptNotes: this.receiptNotes,
        birthday: this.birthday,
        email: this.email,
        website: this.website,
        tel_private: this.tel_private,
        mobil_private: this.mobil_private,
        tel_business: this.tel_business,
        mobil_business: this.mobil_business,
        fax: this.fax,
        customerGroup: this.custGroupChooser,
        notes: this.notes,
        VATID: this.vatID,
        custDebitorenNr: this.debitorAccount,
        IBAN: this.iban,
        KontoInhaber: this.accountOwner,
        Mandatsreferenznummer: this.mandatRef,
        paymentDeadlineDays: this.paymentDueTerms,
        custKreditorenNr: this.kreditorAcc,
        BIC: this.bic,
        BankName: this.bankName,
        glauebigerID: this.creditorID,
        custPaymentTerms: this.termsOfPayment,
        payment_type: this.payment_type,
        bill_allowed: this.bill_allowed,
      }).then((res) => {
        if (res.data.success === true) {
          Events.$emit("showSnackbar", {
            message: this.$t('customers.lang_customerCreated'),
            color: "success"
          });
          this.resetFields();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    getData: function () {
      this.loading = true;

      this.id = parseInt(this.$parent.id);
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETCREATE, {}).then((res) => {
        if (res.status === 200) {
          this.loading = false;


          res.data.custGroups.forEach((row) => {
            this.custGroups.push({name: row[1], id: parseInt(row[0])});
          });

          res.data.salutations.forEach((row) => {
            this.salutations.push({name: row[1], id: parseInt(row[0])});
          });

          res.data.departments.forEach((row) => {
            this.departments.push({name: row[1], id: parseInt(row[0])});
          });

        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    resetFields() {
      this.tab = 0;
      this.company = '';
      this.salutation = '';
      this.department = '';
      this.preName = '';
      this.lastName = '';
      this.streetName = '';
      this.streetNumber = '';
      this.zip = '';
      this.city = '';
      this.country = '';
      this.receiptNotes = '';
      this.birthday = '';
      this.email = '';
      this.website = '';
      this.tel_private = '';
      this.mobil_private = '';
      this.tel_business = '';
      this.mobil_business = '';
      this.fax = '';
      this.custGroupChooser = '';
      this.notes = '';
      this.vatID = '';
      this.debitorAccount = '';
      this.iban = '';
      this.accountOwner = '';
      this.mandatRef = '';
      this.paymentDueTerms = '';
      this.kreditorAcc = '';
      this.bic = '';
      this.bankName = '';
      this.creditorID = '';
      this.termsOfPayment = '';
      this.bill_allowed = false;
      this.position = [];
    }
  },
  mounted() {
    this.getData();
  },

}
</script>


<style>
label {
  margin-bottom: 0px !important;
}
</style>
