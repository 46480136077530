<template>
    <div>
        <page-title :heading="$t('customers.lang_add_customer') " show-previous-button :subheading="$t('customers.lang_add_customer')" :icon=icon></page-title>
        <div class="app-main__inner">
            <CreateCustomer></CreateCustomer>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import CreateCustomer from "../../components/customers/CreateCustomer";

    export default {
        components: {
            PageTitle,
            CreateCustomer
        },
        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>